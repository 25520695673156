.border-none[data-v-73c0602b]:before {
  display: none;
}
.border-none[data-v-73c0602b]:after {
  display: none;
}
.flex-fix[data-v-73c0602b] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-73c0602b]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-73c0602b] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-73c0602b] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-73c0602b] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-73c0602b] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-73c0602b] {
  *zoom: 1;
}
.clear-fix[data-v-73c0602b]:before,
.clear-fix[data-v-73c0602b]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.alert-container[data-v-73c0602b] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
}
.alert-container .alert-wrapper[data-v-73c0602b] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  padding-bottom: 0.88rem;
  padding-left: 0.32rem;
  width: 100%;
  max-height: 6.94rem;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper main .item-list .item[data-v-73c0602b] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 1.6rem;
  border-bottom: 1px solid #d8d8d8;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper main .item-list .item .img-box[data-v-73c0602b] {
  width: 0.96rem;
  height: 0.96rem;
  margin-right: 0.08rem;
}
.alert-container .alert-wrapper main .item-list .item .img-box img[data-v-73c0602b] {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.alert-container .alert-wrapper main .item-list .item .img-right[data-v-73c0602b] {
  max-width: 5.3rem;
}
.alert-container .alert-wrapper main .item-list .item .img-right .item-name[data-v-73c0602b] {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.alert-container .alert-wrapper main .item-list .item .close-btn-wrap[data-v-73c0602b] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
}
.alert-container .alert-wrapper main .item-list .item .close-btn[data-v-73c0602b] {
  width: 0.2em;
  height: 1em;
  background: #666;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: inline-block;
  cursor: pointer;
}
.alert-container .alert-wrapper main .item-list .item .close-btn[data-v-73c0602b]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2em;
  height: 1em;
  background: #666;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.alert-container .alert-wrapper main .item-list .item[data-v-73c0602b]:last-child {
  border-bottom: 0;
}
