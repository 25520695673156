.fill-logistics {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1900;
  background: rgba(0, 0, 0, 0.5);
}
.fill-container {
  height: 7.2rem;
  width: 6.3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
  background: #fff;
  animation: tipMove 0.4s;
  border-radius: 10px;
  padding: 0.34rem 0.4rem 0.44rem 0.44rem;
}
.fill-container .title {
  font-size: 0.32rem;
  color: #333;
  line-height: 0.5rem;
  text-align: center;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-all;
}
.fill-container .content-box {
  width: 100%;
}
.fill-container .content-box .yd-input {
  height: 0.92rem;
  border-bottom: 1px solid #F0F0F0;
  font-size: 0.32rem;
}
.fill-container .content-box input::placeholder {
  color: #999;
}
.fill-container .content-box .yd-cell-item {
  padding-left: 0;
  border-bottom: 1px solid #F0F0F0;
}
.fill-container .content-box .yd-cell-right {
  height: 0.92rem;
}
.fill-container .content-box .yd-cell-right select {
  margin-left: 0;
  font-size: 0.32rem;
  color: #999;
}
.fill-container .content-box .font-select {
  color: #333 !important;
}
.fill-container .content-box .yd-cell-item:not(:last-child):after {
  background-image: none;
}
.fill-container .btn {
  width: 100%;
  height: 0.72rem;
  border-radius: 36px;
  background: var(--main-color);
  color: #fff;
  line-height: 0.72rem;
  text-align: center;
  font-size: 0.28rem;
}
