.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.alert-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
}
.alert-container .alert-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  padding-bottom: 0.88rem;
  padding-left: 0.32rem;
  width: 100%;
  max-height: 6.94rem;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.alert-container .alert-wrapper main .item-list .item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.6rem;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}
.alert-container .alert-wrapper main .item-list .item .img-box {
  width: 0.96rem;
  height: 0.96rem;
  margin-right: 0.08rem;
}
.alert-container .alert-wrapper main .item-list .item .img-box img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.alert-container .alert-wrapper main .item-list .item .img-right {
  max-width: 5.3rem;
}
.alert-container .alert-wrapper main .item-list .item .img-right .item-name {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.alert-container .alert-wrapper main .item-list .item .close-btn-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
}
.alert-container .alert-wrapper main .item-list .item .close-btn {
  width: 0.2em;
  height: 1em;
  background: #666;
  transform: rotate(45deg);
  display: inline-block;
  cursor: pointer;
}
.alert-container .alert-wrapper main .item-list .item .close-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2em;
  height: 1em;
  background: #666;
  transform: rotate(270deg);
}
.alert-container .alert-wrapper main .item-list .item:last-child {
  border-bottom: 0;
}
