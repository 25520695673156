.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  padding-top: 0.2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.24rem;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}
.page .service {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.6rem;
  top: 1.98rem;
  z-index: 99;
}
.page .user-remark {
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 99;
}
.page .order-list {
  position: relative;
}
.page .order-list .order-item {
  display: block;
  position: relative;
  margin-bottom: 0.48rem;
  z-index: 1;
}
.page .order-list .order-item .order-title {
  position: relative;
  padding-left: 24px;
  margin-bottom: 0.2rem;
}
.page .order-list .order-item .order-title::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0.04rem;
  height: 0.2rem;
  background-color: #6C57FF;
  border-radius: 1px;
}
.page .order-list .order-item .order-title .order-number {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
}
.page .order-list .order-item .order-cont {
  position: relative;
  width: 100%;
  min-height: 1.92rem;
  padding-left: 2rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
  padding-right: 0.24rem;
  background-color: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
}
.page .order-list .order-item .order-cont .goods-img-box {
  position: absolute;
  top: 0.16rem;
  left: 0.2rem;
  width: 1.54rem;
  height: 1.54rem;
}
.page .order-list .order-item .order-cont .goods-img-box img {
  width: 100%;
  height: 100%;
}
.page .order-list .order-item .order-cont .img-right {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.page .order-list .order-item .order-status {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  font-weight: bold;
}
.page .order-list .order-item:last-child {
  margin-bottom: 0;
}
.page .bottom {
  margin-top: 0.3rem;
  bottom: 0.24rem;
  left: 0;
  right: 0;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
.page .kf-box {
  background-color: #fff;
  border-radius: 0.2rem;
  position: relative;
}
.page .kf-box img {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.page .kf-box .kf-mobile {
  display: flex;
  height: 2.6rem;
  font-size: 0.38rem;
  justify-content: center;
  align-items: center;
}
.page .kf-box .btn-box {
  width: 100%;
  display: flex;
  font-size: 0.3rem;
  height: 0.8rem;
}
.page .kf-box .btn-box .dial {
  width: 50%;
  color: #fff;
  background: var(--main-color);
  text-align: center;
  line-height: 0.8rem;
  border-radius: 0 0 0 0.2rem;
}
.page .kf-box .btn-box .dial a {
  display: block;
}
.page .kf-box .btn-box button {
  width: 50%;
  font-size: 0.3rem;
  padding: 0;
  border-radius: 0 0 0.2rem 0;
  height: 0.8rem;
  background: #f1f1f1;
  color: var(--main-color);
}
