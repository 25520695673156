.border-none[data-v-157dda5e]:before {
  display: none;
}
.border-none[data-v-157dda5e]:after {
  display: none;
}
.flex-fix[data-v-157dda5e] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-157dda5e]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-157dda5e] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-157dda5e] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-157dda5e] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-157dda5e] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-157dda5e] {
  *zoom: 1;
}
.clear-fix[data-v-157dda5e]:before,
.clear-fix[data-v-157dda5e]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.alert-container[data-v-157dda5e] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.alert-container .alert-wrapper[data-v-157dda5e] {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 3.12rem;
  width: 6rem;
  padding: 0.64rem 0.36rem 0.3rem;
  background-color: #fff;
  border-radius: 0.08rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper header[data-v-157dda5e] {
  margin-bottom: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert-container .alert-wrapper header .title[data-v-157dda5e] {
  font-size: 0.36rem;
  color: #333;
  font-size: 0.3rem;
  width: 40%;
  text-align: center;
  margin-left: 5%;
  padding: 0.1rem;
}
.alert-container .alert-wrapper header .titlecheck[data-v-157dda5e] {
  color: coral;
}
.alert-container .alert-wrapper main[data-v-157dda5e] {
  margin-bottom: 0.48rem;
}
.alert-container .alert-wrapper main .button[data-v-157dda5e] {
  float: right;
  height: 100%;
  width: 45%;
  border: 0;
  text-align: center;
  line-height: 3;
}
.alert-container .alert-wrapper main .btnsms[data-v-157dda5e] {
  background: #ff4c4c;
  color: #fff;
  border-radius: 0.1rem;
  font-size: 0.3rem;
}
.alert-container .alert-wrapper main .form .form-item[data-v-157dda5e] {
  width: 100%;
  height: 0.88rem;
  background-color: #f7f7f7;
  margin-bottom: 0.24rem;
  border-radius: 0.08rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper main .form .form-item .inputboxsms[data-v-157dda5e] {
  float: left;
  width: 50%;
  padding: 0.22rem 0.24rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper main .form .form-item .input-box[data-v-157dda5e] {
  padding: 0.22rem 0.24rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.alert-container .alert-wrapper main .form .form-item .input-box .input[data-v-157dda5e] {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  border: none;
  outline: none;
}
.alert-container .alert-wrapper main .form .form-item .input-box .inputSms[data-v-157dda5e] {
  display: block;
  width: 50%;
  height: 100%;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  border: none;
  outline: none;
}
.alert-container .alert-wrapper main .form .form-item[data-v-157dda5e]:last-child {
  margin-bottom: 0;
}
.alert-container .alert-wrapper footer .submit-btn[data-v-157dda5e] {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.44rem;
  text-align: center;
  white-space: nowrap;
  background-color: #ff4c4c;
  border-radius: 0.08rem;
}
.alert-container .alert-wrapper .close-btn-wrap[data-v-157dda5e] {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.88rem;
  height: 0.88rem;
}
.alert-container .alert-wrapper .close-btn[data-v-157dda5e] {
  width: 0.2em;
  height: 1em;
  background: #666;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: inline-block;
  cursor: pointer;
}
.alert-container .alert-wrapper .close-btn[data-v-157dda5e]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2em;
  height: 1em;
  background: #666;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
