.border-none[data-v-b401a8e0]:before {
  display: none;
}
.border-none[data-v-b401a8e0]:after {
  display: none;
}
.flex-fix[data-v-b401a8e0] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-b401a8e0]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-b401a8e0] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-b401a8e0] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-b401a8e0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-b401a8e0] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-b401a8e0] {
  *zoom: 1;
}
.clear-fix[data-v-b401a8e0]:before,
.clear-fix[data-v-b401a8e0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-b401a8e0] {
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page header[data-v-b401a8e0] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0.88rem;
  padding-left: 0.24rem;
  padding-right: 0.16rem;
  line-height: 0.88rem;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page header .rule[data-v-b401a8e0] {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .kf-box[data-v-b401a8e0] {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .kf-box img[data-v-b401a8e0] {
  margin-top: -2px;
  width: 20px;
  vertical-align: middle;
}
.page header .check-order-btn .txt[data-v-b401a8e0] {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .check-order-btn .icon-rightarrow[data-v-b401a8e0] {
  display: inline-block;
  width: 0.24rem;
  height: 0.24rem;
}
.page .no-stock[data-v-b401a8e0] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.page .sq-img[data-v-b401a8e0] {
  position: absolute;
  width: 0.8rem;
  right: 0;
  z-index: 99999;
}
.page .sq-img1[data-v-b401a8e0] {
  position: absolute;
  width: 0.8rem;
  left: 0;
  z-index: 9;
}
.page main .six-item-list[data-v-b401a8e0] {
  *zoom: 1;
  padding-left: 0.2rem;
  padding-right: 0.18rem;
  padding-bottom: 0.24rem;
}
.page main .six-item-list[data-v-b401a8e0]:before,
.page main .six-item-list[data-v-b401a8e0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page main .six-item-list .item[data-v-b401a8e0] {
  position: relative;
  float: left;
  _display: inline;
  width: 2.32rem;
  height: 3.44rem;
  border: 0.04rem solid #fff;
  margin-top: 0.24rem;
  margin-right: 0.08rem;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page main .six-item-list .item .img-box[data-v-b401a8e0] {
  width: 100%;
  height: 2.32rem;
  overflow: hidden;
}
.page main .six-item-list .item .img-box img[data-v-b401a8e0] {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page main .six-item-list .item .img-bottom[data-v-b401a8e0] {
  padding: 0.16rem 0.08rem;
  width: 100%;
  height: 0.8rem;
}
.page main .six-item-list .item .img-bottom .item-name[data-v-b401a8e0] {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page main .six-item-list .item[data-v-b401a8e0]:nth-child(3n) {
  margin-right: 0;
}
.page main .six-item-list .item.active[data-v-b401a8e0] {
  border: 0.04rem solid #ff4c4c;
}
.page main .six-item-list .item.active[data-v-b401a8e0]::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.48rem;
  height: 0.48rem;
  background-image: url("../../assets/icon_chooseon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.page main .four-item-list[data-v-b401a8e0] {
  *zoom: 1;
  padding-left: 0.48rem;
  padding-right: 0.46rem;
  padding-bottom: 0.24rem;
}
.page main .four-item-list[data-v-b401a8e0]:before,
.page main .four-item-list[data-v-b401a8e0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page main .four-item-list .item[data-v-b401a8e0] {
  position: relative;
  float: left;
  _display: inline;
  width: 3.2rem;
  height: 3.2rem;
  border: 0.04rem solid #fff;
  margin-top: 0.2rem;
  margin-right: 0.14rem;
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page main .four-item-list .item .img-box[data-v-b401a8e0] {
  width: 2.88rem;
  height: 2.2rem;
  margin: 0 auto;
  overflow: hidden;
}
.page main .four-item-list .item .img-box img[data-v-b401a8e0] {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page main .four-item-list .item .img-bottom[data-v-b401a8e0] {
  padding: 0.16rem 0.16rem 0.32rem;
}
.page main .four-item-list .item .img-bottom .item-name[data-v-b401a8e0] {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page main .four-item-list .item[data-v-b401a8e0]:nth-child(2n) {
  margin-right: 0;
}
.page main .four-item-list .item.active[data-v-b401a8e0] {
  border: 0.04rem solid #ff4c4c;
}
.page main .four-item-list .item.active[data-v-b401a8e0]::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.48rem;
  height: 0.48rem;
  background-image: url("../../assets/icon_chooseon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.page main .pagination[data-v-b401a8e0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page main .pagination .btn[data-v-b401a8e0] {
  width: 1.76rem;
  height: 0.64rem;
  font-size: 0.28rem;
  color: #ff4c4c;
  line-height: 0.64rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 0.08rem;
}
.page main .pagination .current-page[data-v-b401a8e0] {
  width: 2.48rem;
  height: 0.64rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.64rem;
  text-align: center;
  white-space: nowrap;
}
.page footer[data-v-b401a8e0] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 1.12rem;
  padding-left: 0.26rem;
  background-color: #fff;
  z-index: 5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page footer .selected[data-v-b401a8e0] {
  display: inline-block;
  margin-right: 0.16rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  font-weight: bold;
}
.page footer .selected .num[data-v-b401a8e0] {
  display: inline-block;
  margin-left: 0.08rem;
  margin-right: 0.08rem;
  font-size: 0.32rem;
  color: #ff4c4c;
  line-height: 0.44rem;
  font-weight: bold;
}
.page footer .view-details-btn .txt[data-v-b401a8e0] {
  margin-right: 0.06rem;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
}
.page footer .view-details-btn .icon-arrow[data-v-b401a8e0] {
  display: inline-block;
  width: 0.14rem;
  height: 0.14rem;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.page footer .view-details-btn.active .icon-arrow[data-v-b401a8e0] {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page footer .submit-btn[data-v-b401a8e0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 1.12rem;
  font-size: 0.36rem;
  color: #fff;
  line-height: 1.12rem;
  text-align: center;
  white-space: nowrap;
  background-color: #ff4c4c;
  z-index: 6;
}
.up-tip[data-v-b401a8e0] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0.24rem;
  width: 1.6rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 0.28rem;
  z-index: 5;
}
.up-tip .icon-uparrow[data-v-b401a8e0] {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.08rem;
  vertical-align: middle;
}
.up-tip .tip[data-v-b401a8e0] {
  font-size: 0.24rem;
  color: #333;
}
.music-wrap[data-v-b401a8e0] {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 0.78rem;
  height: 0.78rem;
}
.music-wrap img[data-v-b401a8e0] {
  width: 100%;
  height: 100%;
}
.icon-size[data-v-b401a8e0] {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  left: 50%;
  margin-left: -0.75rem;
}
