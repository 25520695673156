.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0.88rem;
  padding-left: 0.24rem;
  padding-right: 0.16rem;
  line-height: 0.88rem;
  background-color: #fff;
  box-sizing: border-box;
}
.page header .rule {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .kf-box {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .kf-box img {
  margin-top: -2px;
  width: 20px;
  vertical-align: middle;
}
.page header .check-order-btn .txt {
  font-size: 0.28rem;
  color: #ff4c4c;
}
.page header .check-order-btn .icon-rightarrow {
  display: inline-block;
  width: 0.24rem;
  height: 0.24rem;
}
.page .no-stock {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.page .sq-img {
  position: absolute;
  width: 0.8rem;
  right: 0;
  z-index: 99999;
}
.page .sq-img1 {
  position: absolute;
  width: 0.8rem;
  left: 0;
  z-index: 9;
}
.page main .six-item-list {
  *zoom: 1;
  padding-left: 0.2rem;
  padding-right: 0.18rem;
  padding-bottom: 0.24rem;
}
.page main .six-item-list:before,
.page main .six-item-list:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page main .six-item-list .item {
  position: relative;
  float: left;
  _display: inline;
  width: 2.32rem;
  height: 3.44rem;
  border: 0.04rem solid #fff;
  margin-top: 0.24rem;
  margin-right: 0.08rem;
  background-color: #fff;
  box-sizing: border-box;
}
.page main .six-item-list .item .img-box {
  width: 100%;
  height: 2.32rem;
  overflow: hidden;
}
.page main .six-item-list .item .img-box img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page main .six-item-list .item .img-bottom {
  padding: 0.16rem 0.08rem;
  width: 100%;
  height: 0.8rem;
}
.page main .six-item-list .item .img-bottom .item-name {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page main .six-item-list .item:nth-child(3n) {
  margin-right: 0;
}
.page main .six-item-list .item.active {
  border: 0.04rem solid #ff4c4c;
}
.page main .six-item-list .item.active::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.48rem;
  height: 0.48rem;
  background-image: url("../../assets/icon_chooseon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.page main .four-item-list {
  *zoom: 1;
  padding-left: 0.48rem;
  padding-right: 0.46rem;
  padding-bottom: 0.24rem;
}
.page main .four-item-list:before,
.page main .four-item-list:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page main .four-item-list .item {
  position: relative;
  float: left;
  _display: inline;
  width: 3.2rem;
  height: 3.2rem;
  border: 0.04rem solid #fff;
  margin-top: 0.2rem;
  margin-right: 0.14rem;
  background-color: #fff;
  box-sizing: border-box;
}
.page main .four-item-list .item .img-box {
  width: 2.88rem;
  height: 2.2rem;
  margin: 0 auto;
  overflow: hidden;
}
.page main .four-item-list .item .img-box img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page main .four-item-list .item .img-bottom {
  padding: 0.16rem 0.16rem 0.32rem;
}
.page main .four-item-list .item .img-bottom .item-name {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  word-wrap: break-word;
  word-break: break-all;
}
.page main .four-item-list .item:nth-child(2n) {
  margin-right: 0;
}
.page main .four-item-list .item.active {
  border: 0.04rem solid #ff4c4c;
}
.page main .four-item-list .item.active::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.48rem;
  height: 0.48rem;
  background-image: url("../../assets/icon_chooseon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.page main .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page main .pagination .btn {
  width: 1.76rem;
  height: 0.64rem;
  font-size: 0.28rem;
  color: #ff4c4c;
  line-height: 0.64rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 0.08rem;
}
.page main .pagination .current-page {
  width: 2.48rem;
  height: 0.64rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.64rem;
  text-align: center;
  white-space: nowrap;
}
.page footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.12rem;
  padding-left: 0.26rem;
  background-color: #fff;
  z-index: 5;
  box-sizing: border-box;
}
.page footer .selected {
  display: inline-block;
  margin-right: 0.16rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  font-weight: bold;
}
.page footer .selected .num {
  display: inline-block;
  margin-left: 0.08rem;
  margin-right: 0.08rem;
  font-size: 0.32rem;
  color: #ff4c4c;
  line-height: 0.44rem;
  font-weight: bold;
}
.page footer .view-details-btn .txt {
  margin-right: 0.06rem;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
}
.page footer .view-details-btn .icon-arrow {
  display: inline-block;
  width: 0.14rem;
  height: 0.14rem;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
.page footer .view-details-btn.active .icon-arrow {
  transform: rotate(180deg);
}
.page footer .submit-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 1.12rem;
  font-size: 0.36rem;
  color: #fff;
  line-height: 1.12rem;
  text-align: center;
  white-space: nowrap;
  background-color: #ff4c4c;
  z-index: 6;
}
.up-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.24rem;
  width: 1.6rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 0.28rem;
  z-index: 5;
}
.up-tip .icon-uparrow {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.08rem;
  vertical-align: middle;
}
.up-tip .tip {
  font-size: 0.24rem;
  color: #333;
}
.music-wrap {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 0.78rem;
  height: 0.78rem;
}
.music-wrap img {
  width: 100%;
  height: 100%;
}
.icon-size {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  left: 50%;
  margin-left: -0.75rem;
}
