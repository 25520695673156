.border-none[data-v-3b15571e]:before {
  display: none;
}
.border-none[data-v-3b15571e]:after {
  display: none;
}
.flex-fix[data-v-3b15571e] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-3b15571e]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-3b15571e] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-3b15571e] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-3b15571e] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-3b15571e] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-3b15571e] {
  *zoom: 1;
}
.clear-fix[data-v-3b15571e]:before,
.clear-fix[data-v-3b15571e]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page .img-box[data-v-3b15571e] {
  width: 2.2rem;
  height: 2.2rem;
  margin: 0.8rem auto 0.84rem;
}
.page .img-box img[data-v-3b15571e] {
  width: 100%;
  height: 100%;
}
.page .tip[data-v-3b15571e] {
  margin-bottom: 0.16rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  text-align: center;
}
.page .gray-tip[data-v-3b15571e] {
  display: block;
  font-size: 0.28rem;
  color: #666;
  line-height: 0.4rem;
  text-align: center;
}
