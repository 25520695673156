.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 1rem;
  box-sizing: border-box;
}
.page .order-top {
  width: 100%;
  height: 1rem;
  background-color: #ff4c4c;
  padding: 0 0.3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page .order-top .top-left {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  color: #fff;
  font-weight: bold;
}
.page .order-top .top-left img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 6px;
}
.page .order-top .top-right {
  color: #fff;
  font-size: 0.3rem;
}
.page .order-address {
  height: 1.6rem;
  background: #fff;
  position: relative;
  padding: 0.36rem 0.34rem;
  box-sizing: border-box;
  line-height: 0.4rem;
}
.page .order-address .user-info {
  font-size: 0.4rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 3px;
}
.page .order-address .user-info .user-name {
  margin-right: 0.26rem;
}
.page .order-address .user-address {
  font-size: 0.22rem;
  color: #666666;
}
.page .order-address .order-line {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.page .kf-box {
  background-color: #fff;
  border-radius: 0.2rem;
  position: relative;
}
.page .kf-box img {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.page .kf-box .kf-mobile {
  display: flex;
  height: 2.6rem;
  font-size: 0.38rem;
  justify-content: center;
  align-items: center;
}
.page .kf-box .btn-box {
  width: 100%;
  display: flex;
  font-size: 0.3rem;
  height: 0.8rem;
}
.page .kf-box .btn-box .dial {
  width: 50%;
  color: #fff;
  background: var(--main-color);
  text-align: center;
  line-height: 0.8rem;
  border-radius: 0 0 0 0.2rem;
}
.page .kf-box .btn-box .dial a {
  display: block;
}
.page .kf-box .btn-box button {
  width: 50%;
  font-size: 0.3rem;
  padding: 0;
  border-radius: 0 0 0.2rem 0;
  height: 0.8rem;
  background: #f1f1f1;
  color: var(--main-color);
}
.page .material-flow {
  padding: 0.3rem 0.3rem 0;
  box-sizing: border-box;
}
.page .material-flow .goods-list {
  width: 100%;
}
.page .material-flow .goods-list .goods-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 1.92rem;
  margin-bottom: 0.24rem;
  padding-left: 0.08rem;
  padding-right: 0.24rem;
  background-color: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
}
.page .material-flow .goods-list .goods-item .service {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.6rem;
  bottom: 0.3rem;
  z-index: 99;
}
.page .material-flow .goods-list .goods-item .user-remark {
  position: absolute;
  left: 2rem;
  bottom: 0.3rem;
  z-index: 99;
}
.page .material-flow .goods-list .goods-item .img-box {
  width: 1.76rem;
  height: 1.76rem;
  margin-right: 0.16rem;
  background-color: #fff;
}
.page .material-flow .goods-list .goods-item .img-box img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page .material-flow .goods-list .goods-item .img-right {
  max-width: 4.66rem;
}
.page .material-flow .goods-list .goods-item .img-right .item-name {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.page .material-flow #tkwl {
  margin-bottom: 0.3rem;
  padding: 0.3rem;
  color: #000;
}
.page .material-flow #tkwl p {
  margin-top: 0.2rem;
}
.page .material-flow .material-box {
  background: #fff;
  padding: 0 0.18rem;
  box-sizing: border-box;
}
.page .material-flow .material-box .material-title {
  height: 1.08rem;
  display: flex;
  align-items: center;
  font-size: 0.26rem;
  color: #333333;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.5rem;
}
.page .material-flow .material-box .material-line {
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  color: #999999;
}
.page .material-flow .material-box .material-line .time {
  width: 0.8rem;
  margin-right: 0.66rem;
}
.page .material-flow .material-box .material-line .time p {
  text-align: center;
  white-space: nowrap;
}
.page .material-flow .material-box .material-line .time p:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.page .material-flow .material-box .material-line .time p:nth-child(2) {
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.page .material-flow .material-box .material-line .info p:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.page .material-flow .material-box .material-line .info p:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.34rem;
}
.page .material-flow .material-box .material-line:nth-child(1) {
  color: #333333;
}
.page .material-flow .flow-empty {
  flex-direction: column;
  width: 6.9rem;
  height: 6.92rem;
  background-color: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.page .material-flow .flow-empty .img-box {
  width: 3.64rem;
  height: 2.58rem;
  margin-bottom: 0.32rem;
}
.page .material-flow .flow-empty .img-box img {
  width: 100%;
  height: 100%;
}
.page .material-flow .flow-empty .tip {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.page .bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1rem;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.page .bottom .collect-btn {
  float: right;
  margin-top: 2.5%;
  margin-right: 0.3rem;
  width: 2rem;
  height: 0.68rem;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.68rem;
  text-align: center;
  white-space: nowrap;
  background-color: #FF4C4C;
  border-radius: 0.45rem;
}
