.border-none[data-v-e1d1c460]:before {
  display: none;
}
.border-none[data-v-e1d1c460]:after {
  display: none;
}
.flex-fix[data-v-e1d1c460] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-e1d1c460]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-e1d1c460] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-e1d1c460] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-e1d1c460] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-e1d1c460] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-e1d1c460] {
  *zoom: 1;
}
.clear-fix[data-v-e1d1c460]:before,
.clear-fix[data-v-e1d1c460]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page[data-v-e1d1c460] {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page .order-top[data-v-e1d1c460] {
  width: 100%;
  height: 1rem;
  background-color: #ff4c4c;
  padding: 0 0.3rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.page .order-top .top-left[data-v-e1d1c460] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.3rem;
  color: #fff;
  font-weight: bold;
}
.page .order-top .top-left img[data-v-e1d1c460] {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 6px;
}
.page .order-top .top-right[data-v-e1d1c460] {
  color: #fff;
  font-size: 0.3rem;
}
.page .order-address[data-v-e1d1c460] {
  height: 1.6rem;
  background: #fff;
  position: relative;
  padding: 0.36rem 0.34rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 0.4rem;
}
.page .order-address .user-info[data-v-e1d1c460] {
  font-size: 0.4rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 3px;
}
.page .order-address .user-info .user-name[data-v-e1d1c460] {
  margin-right: 0.26rem;
}
.page .order-address .user-address[data-v-e1d1c460] {
  font-size: 0.22rem;
  color: #666666;
}
.page .order-address .order-line[data-v-e1d1c460] {
  height: 3px;
  width: 100%;
  background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.page .kf-box[data-v-e1d1c460] {
  background-color: #fff;
  border-radius: 0.2rem;
  position: relative;
}
.page .kf-box img[data-v-e1d1c460] {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.page .kf-box .kf-mobile[data-v-e1d1c460] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.6rem;
  font-size: 0.38rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page .kf-box .btn-box[data-v-e1d1c460] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.3rem;
  height: 0.8rem;
}
.page .kf-box .btn-box .dial[data-v-e1d1c460] {
  width: 50%;
  color: #fff;
  background: var(--main-color);
  text-align: center;
  line-height: 0.8rem;
  border-radius: 0 0 0 0.2rem;
}
.page .kf-box .btn-box .dial a[data-v-e1d1c460] {
  display: block;
}
.page .kf-box .btn-box button[data-v-e1d1c460] {
  width: 50%;
  font-size: 0.3rem;
  padding: 0;
  border-radius: 0 0 0.2rem 0;
  height: 0.8rem;
  background: #f1f1f1;
  color: var(--main-color);
}
.page .material-flow[data-v-e1d1c460] {
  padding: 0.3rem 0.3rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page .material-flow .goods-list[data-v-e1d1c460] {
  width: 100%;
}
.page .material-flow .goods-list .goods-item[data-v-e1d1c460] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  height: 1.92rem;
  margin-bottom: 0.24rem;
  padding-left: 0.08rem;
  padding-right: 0.24rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page .material-flow .goods-list .goods-item .service[data-v-e1d1c460] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: 0.6rem;
  bottom: 0.3rem;
  z-index: 99;
}
.page .material-flow .goods-list .goods-item .user-remark[data-v-e1d1c460] {
  position: absolute;
  left: 2rem;
  bottom: 0.3rem;
  z-index: 99;
}
.page .material-flow .goods-list .goods-item .img-box[data-v-e1d1c460] {
  width: 1.76rem;
  height: 1.76rem;
  margin-right: 0.16rem;
  background-color: #fff;
}
.page .material-flow .goods-list .goods-item .img-box img[data-v-e1d1c460] {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.page .material-flow .goods-list .goods-item .img-right[data-v-e1d1c460] {
  max-width: 4.66rem;
}
.page .material-flow .goods-list .goods-item .img-right .item-name[data-v-e1d1c460] {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-all;
}
.page .material-flow #tkwl[data-v-e1d1c460] {
  margin-bottom: 0.3rem;
  padding: 0.3rem;
  color: #000;
}
.page .material-flow #tkwl p[data-v-e1d1c460] {
  margin-top: 0.2rem;
}
.page .material-flow .material-box[data-v-e1d1c460] {
  background: #fff;
  padding: 0 0.18rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page .material-flow .material-box .material-title[data-v-e1d1c460] {
  height: 1.08rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.26rem;
  color: #333333;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0.5rem;
}
.page .material-flow .material-box .material-line[data-v-e1d1c460] {
  margin-bottom: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #999999;
}
.page .material-flow .material-box .material-line .time[data-v-e1d1c460] {
  width: 0.8rem;
  margin-right: 0.66rem;
}
.page .material-flow .material-box .material-line .time p[data-v-e1d1c460] {
  text-align: center;
  white-space: nowrap;
}
.page .material-flow .material-box .material-line .time p[data-v-e1d1c460]:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.page .material-flow .material-box .material-line .time p[data-v-e1d1c460]:nth-child(2) {
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.page .material-flow .material-box .material-line .info p[data-v-e1d1c460]:nth-child(1) {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.page .material-flow .material-box .material-line .info p[data-v-e1d1c460]:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.34rem;
}
.page .material-flow .material-box .material-line[data-v-e1d1c460]:nth-child(1) {
  color: #333333;
}
.page .material-flow .flow-empty[data-v-e1d1c460] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 6.9rem;
  height: 6.92rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.page .material-flow .flow-empty .img-box[data-v-e1d1c460] {
  width: 3.64rem;
  height: 2.58rem;
  margin-bottom: 0.32rem;
}
.page .material-flow .flow-empty .img-box img[data-v-e1d1c460] {
  width: 100%;
  height: 100%;
}
.page .material-flow .flow-empty .tip[data-v-e1d1c460] {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.page .bottom[data-v-e1d1c460] {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.page .bottom .collect-btn[data-v-e1d1c460] {
  float: right;
  margin-top: 2.5%;
  margin-right: 0.3rem;
  width: 2rem;
  height: 0.68rem;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.68rem;
  text-align: center;
  white-space: nowrap;
  background-color: #FF4C4C;
  border-radius: 0.45rem;
}
