.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.32rem;
  box-sizing: border-box;
}
.page .content {
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  margin-top: 0.7rem;
}
.page .home-icon,
.page .collect-icon {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 0.56rem;
  position: absolute;
  z-index: 110;
}
.page .home-icon img,
.page .collect-icon img {
  width: 0.56rem;
  height: 0.56rem;
}
.page .home-icon {
  left: 0.3rem;
  top: 0.4rem;
}
.page .collect-icon {
  right: 0.3rem;
  top: 0.4rem;
}
